import React from 'react'

import styles from './grow-with-us.module.css'
import '../style.module.css'

import Skeleton from '../components/Skeleton'
import Row from '../components/common/Row'
import GrowWithUsForm from '../components/GrowWithUsForm'
import config from '../config'

const GrowWithUs = () => {

  const {
    growWithUs: {
      // title: pageTitle,
      bannerImage,
      customHero,
    },
  } = config


  return (
    <Skeleton
      disableFooterImage={true}
      bannerImage={bannerImage}
      customHero={customHero}
    >
        <Row className={styles.growContainer}>
            {/* <h1 id={pageTitle} className={styles.growTitle}>
              {pageTitle}
            </h1> */}
              <GrowWithUsForm  />
        </Row>
    </Skeleton>
  )
}

export default GrowWithUs
