import React from 'react'
import config from '../../config'
import styles from './GrowWithUsForm.module.css'
import Gtag from '../../analytics'

const Form = () => {
  const {
    growWithUs: {
      placeholders: {
        name,
        email,
        revenue,
        brandName,
        website,
        additionalInfo
      },
      submitButtonText,
      successMessage,
      formFailedError,
      formPostURL
    }
  } = config

  const initialState = {
    submitted: false,
    invalid: false,
    success: false,
    error: false,
    disabled: false
  }
  const [formState, setFormSubmitted] = React.useState(initialState);

  const disableSubmitButton = (value) => {
    setFormSubmitted((prevFormState) => {
      return {
        ...prevFormState,
        disabled: value,
      }
    })
  }

  const onFormSubmit = async (formData = {}) => {
    Gtag.trackEvent('SUBMIT_GROW_WITH_US_FORM')
    const body = JSON.stringify(formData)

    return fetch(formPostURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: "cors",
      body
    })
      .then((response) => {

        if (!response.ok) {
          throw(response)
        }
        return response.json()
      })
      .then(() => {
        setFormSubmitted((prevFormState) => ({
          ...prevFormState,
          error: false,
          success: true,
        }));
      })
      .catch((e) => {
        Gtag.trackEvent('SUBMIT_FAILED_GROW_WITH_US_FORM', {error: e.message})
        setFormSubmitted((prevFormState) => ({
          ...prevFormState,
          error: true,
        }));
      })
  }

  const handleFormInvalid = (event) => {
    setFormSubmitted((prevFormState) => ({
      ...prevFormState,
      invalid: true
    }))
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    const dataAsObject = Object.fromEntries(data.entries());

    setFormSubmitted(initialState) // Remove any error msg while submitting
    disableSubmitButton(true)
    await onFormSubmit(dataAsObject)
    disableSubmitButton(false)
  }

  if (formState.success) {
   return (
    <h1 className={styles.successMessage}>
      {successMessage}
    </h1>)
  }

  return (
    <form
      method="post"
      action="/form-post"
      onSubmit={handleFormSubmit}
      onInvalid={handleFormInvalid}
      className={styles.growWithUsForm + ` ${formState.invalid ? styles.invalidEnabled : ''}`}
    >
        <div className={styles.halfGridInput}>
          <input 
            name="clientName"
            aria-label="Your name"
            required
            maxLength="50"
            placeholder={name} 
            className={styles.halfGridInput} />
          <p className={styles.invalidMessage}> Please complete this field</p>
        </div>
        <div className={styles.halfGridInput}>
          <input 
            name="clientEmail"
            aria-label="Your email"
            required
            type="email"
            maxLength="50"
            placeholder={email}  />
            <p className={styles.invalidMessage}> Please complete this field with an email</p>
        </div>
        <div className={styles.halfGridInput}>
          <input 
            name="clientRevenue"
            aria-label="Your company revenue"
            inputMode="numeric"
            placeholder={revenue} />
          <p className={styles.invalidMessage}> Please complete this field</p>
        </div>
        <div className={styles.halfGridInput}>
          <input 
            name="clientBrandName"
            aria-label="Your company name"
            required
            maxLength="50"
            placeholder={brandName} 
            className={styles.halfGridInput} />
          <p className={styles.invalidMessage}> Please complete this field</p>
        </div>
        <div className={styles.fullGridInput}>
          <input 
            name="companyUrl"
            aria-label="Your company website, starting with https://"
            type="url" 
            maxLength="50"
            placeholder={website} 
            className={styles.fullGridInput} />
          <p className={styles.invalidMessage}> URL not valid, please use format: https://www.yourwebsite.com</p>
        </div>
        <textarea 
          name="additionalInfo"
          aria-label="Additional info you'd like to provide us"
          maxLength="5000"
          placeholder={additionalInfo} 
          className={styles.additionalInfoInput} />
        <input 
          type="submit"
          disabled={formState.disabled}
          value={submitButtonText}
          className={styles.submit} />
        {
          formState.error ?
          <h3 className={styles.errorMessage}>{formFailedError}</h3> : 
          null
        }
  </form>
  )
}

export default Form;